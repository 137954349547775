const businessType={
        'Beauty and Personal Care':[
            'Beauty Salon',
            'Hair Salon/Barbershop',
            'Independent Stylist/Barber',
            'Massage Therapist',
            'Nail Salon',
            'Spa',
            'Tanning Salon',
            'Tattoo/Piercing',
            'Other'
        ],
        'Casual Use':[
            'Events/Festivals',
            'Miscellaneous Goods',
            'Miscellaneous Services',
            'Outdoor Markets',
            'Other'
        ],
        'Charities, Education and Member':[
            'Charitable Organization',
            'Child Care',
            'Instructor/Teacher',
            'Membership Organization',
            'Political Organization',
            'Religious Organization',
            'School',
            'Tutor',
            'Others'
        ],
        'Food and Drink':[
            'Bakery',
            'Bar/Club/Lounge',
            'Caterer',
            'Coffee/Tea Shop',
            'Convenience Store',
            'Food Truck/Cart',
            'Grocery/Market',
            'Outdoor Markets',
            'Private Chef',
            'Quick Service Restaurant',
            'Sit-Down Restaurant',
            'Specialty Shop',
            'Others'
        ],
        'Health Care and Fitness':[
            'Acupuncture',
            'Alternative Medicine',
            'Caregiver',
            'Chiropractor',
            'Dentist/Orthodontist',
            'Gym/Health Club',
            'Massage Therapist',
            'Medical Practitioner',
            'Optometrist/Eye Care',
            'Personal Trainer',
            'Psychiatrist',
            'Therapist',
            'Veterinary Services',
            'Others'
        ],
        'Home and Repair':[
            'Automotive Services',
            'Carpet Cleaning',
            'Cleaning',
            'Clothing/Shoe Repair/Alterations',
            'Computer/Electronics/Appliances',
            'Dry Cleaning and Laundry',
            'Electrical Services',
            'Flooring',
            'General Contracting',
            'Heating and Air Conditioning',
            'Installation Services',
            'Junk Removal',
            'Landscaping',
            'Locksmith Services',
            'Moving',
            'Painting',
            'Pest Control',
            'Plumbing',
            'Roofing',
            'Watch/Jewelry Repair',
            'Other'
        ],
        'Leisure and Entertainment':[
            'Events/Festivals',
            'Movies/Film',
            'Museum/Cultural',
            'Performing Arts',
            'Sporting Events',
            'Sports Recreation',
            'Tourism',
            'Other'
        ],
        'Professional Services':[
            'Accounting',
            'Child Care',
            'Consulting',
            'Delivery',
            'Design',
            'Interior Design',
            'Legal Services',
            'Marketing/Advertising',
            'Nanny Services',
            'Notary Services',
            'Photography',
            'Printing Services',
            'Real Estate',
            'Software Development',
            'Other'
        ],
        'Retail':[
            'Art, Photo and Film',
            'Books, Mags, Music and Video',
            'Clothing and Accessories',
            'Convenience Store',
            'Electronics',
            'Eyewear',
            'Flowers and Gifts',
            'Furniture/Home Goods',
            'Grocery/Market',
            'Hardware Store',
            'Hobby Shop',
            'Jewelry and Watches',
            'Office Supply',
            'Outdoor Markets',
            'Pet Store',
            'Specialty Shop',
            'Sporting Goods',
            'Other'
        ],
        'Transportation':[
            'Bus',
            'Delivery',
            'Limousine',
            'Moving',
            'Private Shuttle',
            'Taxi',
            'Town Car',
            'Other'
        ]

    }


export default businessType;